<template>
  <div>
    <Loged />
    <header>
      <div class="dahsboard_navBar">
        <div class="logoHeader">
          <a href="https://cams.clubcamgirl.com"><img src="/img/camclub.png" alt="" /></a>
        </div>
        <div class="logout">
          <router-link to="/login"><logout></logout></router-link>
          <span>Log Out</span>
          <div class="navUserImg">
            <img
              v-bind:src="
                !model.profile_img
                  ? '/img/logoupng.png'
                  : model.profile_img
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </header>
    <main v-if="!editmode">
      <div class="container-dashboard">
        <div class="row align-center">
          <div class="col-dashboard col-xl m-t-5">
            <div class="model-personal-info">
              <div class="user_avatar_edit">
                <div class="user_avatar_upload uploading">
                  <img
                    v-bind:src="
                      !model.profile_img
                        ? '/img/logoupng.png'
                        : model.profile_img
                    "
                    alt=""
                  />
                </div>
                <div class="avater_edit" @click="editmode = true">
                  <span >Edit</span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="edit"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    class="svg-inline--fa fa-edit fa-w-18 fa-5x"
                  >
                    <path
                      fill="currentColor"
                      d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                      class=""
                    ></path>
                  </svg>
                </div>
              </div>

              <span class="model-email"> @{{ model.username }} </span>
              <span class="model-link">
                <a>
                  {{
                    model.website == null ? "Add your website!" : model.website
                  }}
                </a>
              </span>
              <span class="model-description m-b-2">
                {{
                  model.Bio == null
                    ? "Add something describe your content"
                    : model.Bio
                }}
              </span>
            </div>
            <div class="model-social-items">
              <p class="tittles"> Personal </p>
              <div class="social-item">
                <div class="social-img whatsapp">
                  <img src="/img/whats.png" alt="" />
                </div>
                <div class="social-data">Number</div>
                <div class="social-name">whatsapp</div>
              </div>
              <div class="social-item m-b-0">
                <div class="social-img telegram">
                  <img src="/img/telegram.png" alt="" />
                </div>
                <div class="social-data">Number</div>
                <div class="social-name">telegram</div>
              </div>
            </div>
            <div class="model-social-items">
              <p class="tittles"> Content Sites </p>
              <div class="social-item">
                <div class="social-img onlyfans">
                  <img src="/img/onlyfans.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">OnlyFans</div>
              </div>
              <div class="social-item m-b-0">
                <div class="social-img patreon">
                  <img src="/img/patreon.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">Patreon</div>
              </div>
              <!-- <div class="add-other">Add Other</div> -->
            </div>
          </div>
          <div class="col-dashboard">
            <div class="model-social-items m-t-r">
              <p class="tittles"> Social </p>
              <div class="social-item">
                <div class="social-img instagram">
                  <img src="/img/instagram.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">Instagram</div>
              </div>
              <div class="social-item">
                <div class="social-img facebook">
                  <img src="/img/facebook.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">Facebook</div>
              </div>
              <div class="social-item">
                <div class="social-img twitter">
                  <img src="/img/twiter.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">Twitter</div>
              </div>
              <div class="social-item m-b-1">
                <div class="social-img tik-tok">
                  <img src="/img/tiktok.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">Tik Tok</div>
              </div>
              <!-- <div class="add-other m-b-0">Add Other</div> -->
            </div>
            <div class="model-social-items">
              <p class="tittles"> Webcam </p>
              <div class="social-item">
                <div class="social-img-xl charturbate">
                  <img src="/img/charturbate.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">chaturbate</div>
              </div>
              <div class="social-item">
                <div class="social-img-xl camsoda">
                  <img src="/img/camsoda.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">CamSoda</div>
              </div>
              <div class="social-item">
                <div class="social-img-xl-2">
                  <img src="/img/bonga.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">BongaCams</div>
              </div>
              <div class="social-item m-b-0">
                <div class="social-img-xl m-l-1">
                  <img src="/img/strip-small.png" alt="" />
                </div>
                <div class="social-data">Link to ...</div>
                <div class="social-name">StripChat</div>
              </div>
              <!-- <div class="add-other">Add Other</div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 
        ELSE
        ELSE
    -->
    <main v-else>
      <div class="container-dashboard">
        <div class="row align-center">
          <div class="col-dashboard col-xl m-t-5">
            <div class="model-personal-info">
              <div class="user_avatar_edit in-edit">
                <div class="editing">
                  <cancel @click.native="editmode = !editmode"></cancel>
                  <save v-on:click.native="updateButtons()"></save>
                </div>
                <div class="user_avatar_upload" v-on:click="inputImg()">
                  <img
                    v-bind:src="
                      !mo
                        ? '/img/logoupng.png'
                        : model.profile_img
                    "
                    alt=""
                  />
                  <input
                    id="hideInp"
                    type="file"
                    accept="image/*"
                    hidden
                    @change="storageImg($event)"
                  />
                </div>
                <div class="user_avatar_info">
                  <span>**(Max Upload File Size 1MB , JPG, PNG).</span>
                  <span>**Recommended Size 400px 400px.</span>
                </div>
                <span class="model-email"> @{{ model.username }} </span>
                <div class="editing-inputs">
                  <div class="web-input">
                    <span>Website</span>
                    <input
                      type="text"
                      v-bind:placeholder="model.website"
                      v-model="model.website"
                      @change="websiteToUpdate = $event.target.value"
                    />
                  </div>
                  <div class="bio-input">
                    <span>Bio <br /></span>
                    <div class="textAreaRow">
                      <textarea
                        type="text"
                        v-model="model.Bio"
                        maxlength="200"
                        @change="BioToUpdate = $event.target.value"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="model-social-items">
              <p class="tittles"> Personal </p>
              <div class="phone-inedit">
                <div class="social-item edit-size">
                  <div class="social-img-inedit whatsapp">
                    <img src="/img/whats.png" alt="" />
                  </div>
                  <div class="phone-edit">
                    <div class="phone-sell">
                      <input
                        type="number"
                        placeholder="+57 222 22 22"
                        v-model="buttons.whatsapp.url"
                        @change="toUpdate($event, buttons.whatsapp.uuid)"
                      />
                      <div class="privacity">
                        <coin></coin>
                        <span> Private - Sell it </span>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="social-item m-b-0">
                <div class="social-img telegram">
                  <img src="/img/telegram.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.telegram.url"
                    @change="toUpdate($event, buttons.telegram.uuid)"
                  />
                </div>
              </div>
            </div>
            <div class="model-social-items">
              <p class="tittles"> Content Sites </p>
              <div class="social-item">
                <div class="social-img onlyfans">
                  <img src="/img/onlyfans.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.onlyfans.url"
                    @change="toUpdate($event, buttons.onlyfans.uuid)"
                  />
                </div>
              </div>
              <div class="social-item m-b-0">
                <div class="social-img patreon">
                  <img src="/img/patreon.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.patreon.url"
                    @change="toUpdate($event, buttons.patreon.uuid)"
                  />
                </div>
              </div>
              <!-- <div class="add-other">
                <div class="add-new">Add Other</div>
              </div> -->
            </div>
          </div>
          <div class="col-dashboard">
            <div class="model-social-items m-t-r">
              <p class="tittles"> Social </p>
              <div class="social-item">
                <div class="social-img instagram">
                  <img src="/img/instagram.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.instagram.url"
                    @change="toUpdate($event, buttons.instagram.uuid)"
                  />
                </div>
              </div>
              <div class="social-item">
                <div class="social-img facebook">
                  <img src="/img/facebook.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.facebook.url"
                    @change="toUpdate($event, buttons.facebook.uuid)"
                  />
                </div>
              </div>
              <div class="social-item">
                <div class="social-img twitter">
                  <img src="/img/twiter.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.twitter.url"
                    @change="toUpdate($event, buttons.twitter.uuid)"
                  />
                </div>
              </div>
              <div class="social-item m-b-1">
                <div class="social-img tik-tok">
                  <img src="/img/tiktok.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to"
                    v-model="buttons.tiktok.url"
                    @change="toUpdate($event, buttons.tiktok.uuid)"
                  />
                </div>
              </div>
              <!-- <div class="add-other m-b-0">
                <div class="add-new">Add Other</div>
              </div> -->
            </div>
            <div class="model-social-items">
              <p class="tittles"> Webcam </p>
              <div class="social-item">
                <div class="social-img-xl charturbate">
                  <img src="/img/charturbate.png" alt="" />
                </div>

                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to ..."
                    v-model="buttons.chaturbate.url"
                    @change="toUpdate($event, buttons.chaturbate.uuid)"
                  />
                </div>
              </div>
              <div class="social-item">
                <div class="social-img-xl camsoda">
                  <img src="/img/camsoda.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to ..."
                    v-model="buttons.camsoda.url"
                    @change="toUpdate($event, buttons.camsoda.uuid)"
                  />
                </div>
              </div>
              <div class="social-item">
                <div class="social-img-xl-2">
                  <img src="/img/bonga.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to ..."
                    v-model="buttons.bongacams.url"
                    @change="toUpdate($event, buttons.bongacams.uuid)"
                  />
                </div>
              </div>
              <div class="social-item m-b-0">
                <div class="social-img-xl m-l-1">
                  <img src="/img/strip-small.png" alt="" />
                </div>
                <div class="name-edit">
                  <input
                    type="text"
                    placeholder="Link to ..."
                    v-model="buttons.stripchat.url"
                    @change="toUpdate($event, buttons.stripchat.uuid)"
                  />
                </div>
              </div>
              <!-- <div class="add-other">
                <div class="add-new">Add Other</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Loged from "../layout/Loged.vue";
import cancel from "../components/littles/cancel.vue";
import save from "../components/littles/save.vue";
import coin from "../components/littles/coin.vue";
import logout from "../components/littles/logout.vue";
import jwtParse from "../functions/decoder";
import Toastify from "toastify-js";
import firebase from "firebase";

export default {
  components: {
    Loged,
    cancel,
    save,
    coin,
    logout,
  },
  metaInfo() {
    return {
      title: `Welcome back!`,
      titleTemplate: "%s - Club Cam Girls!",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      link: [{ rel: "icon", href: "/favicon.png" }],
    };
  },
  data() {
    return {
      editmode: false,
      model: null,
      editProfileImage: null,
      buttons: Object,
      buttonsToUpdate: [],
      BioToUpdate: null,
      websiteToUpdate: null,
    };
  },

  mounted() {
    this.getModelData();
  },

  methods: {
    toUpdate(e, uuid) {
      this.buttonsToUpdate.push([e.target.value, uuid]);
      console.log(this.buttonsToUpdate);
    },

    async updateButtons() {
      const auth = this.$cookies.get("_user_base");
      const data = jwtParse(auth);
      if (this.buttonsToUpdate.length === 0) {
        if (this.BioToUpdate !== null) {
          await this.axios({
            method: "PATCH",
            baseURL: process.env.VUE_APP_BASE_URL,
            url: `/api/v1/models/${data.uuid}`,
            data: {
              Bio: this.BioToUpdate,
            },
            headers: {
              Authorization: auth,
            },
          });
          Toastify({
            text: "New data added 😎",
            duration: 6000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "left", // `left`, `center` or `right`
            backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function () {}, // Callback after click
          }).showToast();
        }

        if (this.websiteToUpdate !== null) {
          await this.axios({
            method: "PATCH",
            baseURL: process.env.VUE_APP_BASE_URL,
            url: `/api/v1/models/${data.uuid}`,
            data: {
              website: this.websiteToUpdate,
            },
            headers: {
              Authorization: auth,
            },
          });
          Toastify({
            text: "New data added 😎",
            duration: 6000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "left", // `left`, `center` or `right`
            backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function () {}, // Callback after click
          }).showToast();
        }
        this.editmode = !this.editmode;
        return;
      }

      if (this.buttonsToUpdate.length > 0) {
        Toastify({
          text: "Applaying changes",
          duration: 6000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();

        if (this.websiteToUpdate !== null) {
          await this.axios({
            method: "PATCH",
            baseURL: process.env.VUE_APP_BASE_URL,
            url: `/api/v1/models/${data.uuid}`,
            data: {
              website: this.websiteToUpdate,
            },
            headers: {
              Authorization: auth,
            },
          });
        }
        if (this.BioToUpdate !== null) {
          await this.axios({
            method: "PATCH",
            baseURL: process.env.VUE_APP_BASE_URL,
            url: `/api/v1/models/${data.uuid}`,
            data: {
              Bio: this.BioToUpdate,
            },
            headers: {
              Authorization: auth,
            },
          });
        }

        for (const i in this.buttonsToUpdate) {
          try {
            await this.axios({
              method: "PATCH",
              baseURL: process.env.VUE_APP_BASE_URL,
              url: `/api/v1/models/buttons/${this.buttonsToUpdate[i][1]}`,
              data: {
                url: this.buttonsToUpdate[i][0],
              },
              headers: {
                Authorization: auth,
              },
            });
          } catch (error) {
            Toastify({
              text: "Error in server, try again 🥵",
              duration: 6000,
              newWindow: true,
              close: true,
              gravity: "top", // `top` or `bottom`
              position: "left", // `left`, `center` or `right`
              backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
              stopOnFocus: true, // Prevents dismissing of toast on hover
              onClick: function () {}, // Callback after click
            }).showToast();
            console.log(error);
          }
        }
        Toastify({
          text: "New buttons links 😎",
          duration: 6000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        this.editmode = !this.editmode;
        this.buttonsToUpdate = [];
        return;
      }
      Toastify({
        text: "No buttons added 😓",
        duration: 3000,
        destination: "https://github.com/apvarun/toastify-js",
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "left", // `left`, `center` or `right`
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        stopOnFocus: true, // Prevents dismissing of toast on hover
        onClick: function () {}, // Callback after click
      }).showToast();
    },

    async getModelData() {
      const auth = this.$cookies.get("_user_base");
      const data = jwtParse(auth);
      const model = await this.axios({
        method: "GET",
        baseURL: process.env.VUE_APP_BASE_URL,
        url: `/api/v1/models/${data.uuid}`,
      });
      this.model = model.data.msg;

      this.model.buttons.map((button) =>{
        const title = button.title;
        this.buttons[title] = {
          uuid: button.uuid,
          url: button.url,
          logo: button.logo,
          color: button.color,
          private: button.private,
          type: button.type,
        };
      });
    },

    inputImg() {
      document.getElementById("hideInp").click();
    },

    async storageImg(e) {
      const auth = this.$cookies.get("_user_base");
      const data = jwtParse(auth);
      this.editProfileImage = e.target.files[0];
      this.model.profile_img = '/img/loader.gif'
      const ref = firebase.storage().ref();
      const refImg = ref.child("profile-imgs/" + this.editProfileImage.name);
      const metada = { contentType: "img/jpeg" };
      await refImg.put(this.editProfileImage, metada);
      await refImg.getDownloadURL().then((url) =>{
        this.axios({
          method: "PATCH",
          baseURL: process.env.VUE_APP_BASE_URL,
          url: `/api/v1/models/${data.uuid}`,
          data: { profile_img: url },
          headers: { Authorization: auth },
        });

        this.model.profile_img = url
      });
      Toastify({
        text: "Image Uploaded",
        duration: 3000,
        destination: "https://github.com/apvarun/toastify-js",
        newWindow: true,
        close: true,
        gravity: "top", // `top` or `bottom`
        position: "left", // `left`, `center` or `right`
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        stopOnFocus: true, // Prevents dismissing of toast on hover
        onClick: function () {}, // Callback after click
      }).showToast();
    },
  },
};
</script>