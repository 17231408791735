<script>
import jwtParse from '../functions/decoder'
import * as validate from 'uuid-validate'
export default {
    name: "Loged",
    mounted () {
        this.getToken()
    },
    methods: {
        getToken() {
            const auth = this.$cookies.get('_user_base')
            if (auth === null) {
                this.$router.push('/login')
            }
            try{
            const data = jwtParse(auth)
            const uuid = validate(data.uuid)
            if(!uuid){
               this.$router.push('/login') 
            }
            }catch(error){
                this.$router.push('/login')
            }
        }
    }
}
</script>